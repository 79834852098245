.main{
    width: 100%;
    /* height: 5rem !important;  */
}

.carouselImg{
    width: 100%;
    height: 40rem;
}

.imgs{
    height: 40rem;
}

@media (max-width: 720px) {
    .carouselImg{
        width: 100%;
        height: 25rem;
    }
    .imgs{
        height: 20em;
    }
  }