/* Contact Us CSS */

.contact-us-container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


.contactUsHeading{
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
  color: #0A2451;
  text-decoration: underline;
  text-shadow: 2px 4px 4px rgba(46,91,173,0.6);
  letter-spacing: 5px;
}

.contact-us-header {
  text-align: center;
  margin-bottom: 20px;
}

.contact-us-header h1 {
  font-size: 30px;
  font-weight: bold;
  color: #0a2451;
}

.contact-us-header p {
  font-size: 16px;
  color: #666;
}

.contact-us-body {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.contact-us-info {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
}

.contact-us-info h4 {
  font-size: 20px;
  font-weight: bold;
  color: #0a2451;
}

.contact-us-info p {
  font-size: 16px;
  color: #666;
}
