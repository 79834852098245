* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.marquee {
  overflow: hidden;
  margin: 3em auto;
  white-space: nowrap;
}

.marquee-content {
  display: inline-flex;
  animation: ferrisWheel 30s linear infinite;
  
  
  &:hover {
    animation-play-state: paused;
  }
}

.marquee-item {
  margin: 0 1vw;
}

.marquee-item img {
  display: block;
  max-width: 200px;
  max-height: 150px;
}

.Hiring-partners {
  text-align: center;
  padding-top: 50px;
  color: #0A2451;
  text-decoration: underline;
  text-shadow: 2px 4px 4px rgba(46, 91, 173, 0.6);
  letter-spacing: 5px;
}

@keyframes ferrisWheel {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Move the content by 100% to the left */
  }
}
