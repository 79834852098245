.navbar {
    background-color: "black" !important;
}

.dropdown {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }


  @media (max-width: 576px) {
  .HeaderImg{
    height: 8em !important; 
    width: "100%";
    padding: 2px
  }
}
  