.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-form input,
.upload-form button {
  margin: 5px 0;
  padding: 10px;
  width: 100%;
}

.upload-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.upload-form button:hover {
  background-color: #0056b3;
}

/* Image Preview Styling */
.image-preview {
  width: 200px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-image-text {
  font-size: 16px;
  color: #999;
  text-align: center;
}

/* Center the "No Image Selected" text when no image is chosen */
.no-image-text::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}


.success-alert {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
}

/* Add these styles to your stylesheet */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #4caf50;
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  
  
}

.modal-close {
  cursor: pointer;

}
