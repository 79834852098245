@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

* {
  box-sizing: border-box;
}

.testimonal_wrapper{
  display: flex;
  flex-direction: row-reverse;
}

.testimonial-container {
  background-color: #0A2451;
  color: white;
  /* border-radius: 15px; */
  /* margin: 30px; */
  /* padding: 50px 80px; */
  /* position: relative; */
  /* height: auto; */
}

.fa-quote {
  color: rgba(255, 255, 255, 0.3);
  font-size: 28px;
  position: absolute;
  top: 70px;
}

.fa-quote-left {
  left: 40px;
}

.fa-quote-right {
  right: 40px;
}

.testimonial {
  line-height: 28px;
  /* text-align: justify; */
  margin-left:25px
}

.user {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.user .user-image {
  /* border-radius: 50%; */
  padding-right: 10px;
  height: 75px;
  width: 75px;
  object-fit: cover;
}

.user .user-details {
  margin-left: 10px;
  margin-top: 10px;
}

.user .username {
  margin: 0;
}

.user .role {
  font-weight: bold;
  font-size: 16px !important;
  margin: 10px 0;
  color: orange;
}

.progress-bar {
  background-color: white;
  height: 4px;
  width: 100%;
  animation: grow 10s linear infinite;
  transform-origin: left;
}
.textp{
  font-size: 20px !important;
  color:whitesmoke;
  margin-top:40px;

  /* font-size: 16px; */
  /* color: #999; */
  max-width: 70%;
  /* margin: 0 auto; */
  
}
@keyframes grow {
  0% {
    transform: scaleX(0);
  }
}

@media (max-width: 576px) {
  .testimonial-container {
    padding: 20px;
    margin: 15px;
  }

  .testimonal_wrapper{
    display: inline-grid;
    
    /* flex-direction: row-reverse; */
  }

  .fa-quote {
    display: none;
  }

  .textp {
    display: block;
    width: 100%;
    /* overflow: hidden; */
    white-space: wrap;
    text-overflow: ellipsis;
    /* margin-left: 20px !important; */
    margin-top: 1rem;
    margin-left: 10px;
  }
  .readMore{
    /* margin:10px */
    margin-top:20px !important;
    margin-bottom:20px !important
  }
  .user .user-image {
    height: 150px;
    width: 150px;
    margin-left: 30px;
  }
  
  .user .user-details {
    margin-left: 30px;
  }

  .user .username {
    font-size: 14px;
  }

  .user .role {
    font-size: 8px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .testimonial-container {
    padding: 20px 30px;
    margin: 20px;
  }

  .fa-quote {
    display: none;
  }

  .user .user-image {
    height: 60px;
    width: 60px;
  }

  .user .username {
    font-size: 16px;
  }

  .user .role {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .testimonial-container {
    padding: 30px 50px;
    margin: 30px;
  }

  .user .user-image {
    height: 75px;
    width: 75px;
  }

  .user .username {
    font-size: 18px;
  }

  .user .role {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .testimonial-container {
    padding: 30px 50px;
    margin: 50px;
  }

  .user .user-image {
    height: 200px;
    width: 175px;
    margin-top: 25px;
  }

  .user .username {
    font-size: 20px;
  }

  .user .role {
    font-size: 18px;
  }

}

.messageshifter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width:60%; */
  /* max-width: 60%; */
}

@media (max-width: 992px) {
  .messageshifter-container {
    flex-direction: column;
    /* margin:"auto" */
  }
}





