.first-latter{
   
    color:#fff;
    font-weight: bold; 
  }
  
  .p_text{
    color:#09a880;
    margin-bottom:0px ;
    padding-bottom: 16px;
  }

  .footerText{
    align-items: center;
    text-align: center;
  }