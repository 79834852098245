
.adminBody{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 60px 0;
    margin: 0;
    color: #36393f;
    background: #ecf4ff;
    background-size: 16px 16px;
    font-family: "Euclid Circular A", "Poppins";
}


.photogalleryHeading{
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
  color: #0A2451;
  text-decoration: underline;
  text-shadow: 2px 4px 4px rgba(46,91,173,0.6);
  letter-spacing: 5px;
}

.options{
    width: 200px;
    // color: var(--color);
    transition: 0.75s;
    
    &:hover,
    &:focus { 
      border-color: var(--hover);
      color: #fff;
     
    }
}

.upload:hover,
.upload:focus {
    box-shadow: inset 0 -3.25em 0 0 var(--hover);
}


.delete:hover,
.delete:focus {
    box-shadow: inset 14.5em 0 0 0 #aa3d17, inset 0 0 0 12.5em #f69697; 
}





#one {
    background: rgb(255,210,207);
    background: linear-gradient(0deg, rgba(255,210,207,1) 0%, rgba(255,237,207,0.8085828081232493) 76%);
}




.background {
    position: fixed;
    z-index: -1;
    top: -100px;
    left: -100px;
    right: -100px;
    height: 60vh;
    rotate: -4deg;
    background: #000000;
    
  }
  
  .card {
    display: grid;
    place-items: center;
    gap: 10px;
    order: 2;
    width: 75%;
    margin: 0 auto;
    border-radius: 6px;
    padding: 50px 10px 50px 10px;
    background: rgb(255,210,207);
    background: linear-gradient(0deg, rgba(255,210,207,1) 0%, rgba(255,237,207,0.8085828081232493) 76%);
    font-size: 125%;
  }
  
  .card.primary {
    order: 1;
  }
  
  .card.primary var {
    font-size: 60px;
  }
  
  @media (width >= 660px) {
    .card {
      width: 60vw;
    }
  }
  
  @media (width >= 960px) {
    body {
      padding: 0;
      flex-direction: row;
    }
  
    body,
    html {
      height: 100%;
    }
  
    .card {
      width: 300px;
      max-width: 300px;
      margin: 0;
      font-size: 100%;
    }
  
    .card.primary {
      order: 2;
    }
  }
  
  p,
  h2 {
    margin: 0;
  }
  
  .card var {
    color: #0f71ff;
    font-weight: 400;
    font-size: 50px;
    font-style: normal;
    margin-bottom: 10px;
  }
  
  .card var small {
    font-size: 16px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
    list-style-type: disc;
  }
  
  ul li {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 6px 0;
  }
  

  



$colors: (
  fill: #f94449,
  slide: #8fc866, 
  offset: #19bc8b
);


  .card button {
    display: grid;
    margin: 20px auto;
    place-items: center;
    min-width: auto;
    padding: 14px;
    border-radius: 6px;
    border: 2px solid #0f71ff;
    background: transparent;
    color: #0f71ff;
    font-size: inherit;
    font-family: inherit;
    height: 40px;
  }
  
  .card button.primary {
    background: #0f71ff;
    color: #f7f7f7;
  }


  @each $button, $color in $colors {
    .#{$button} {
      --color: #{$color};
      --hover: #{adjust-hue($color, 45deg)};
    }
  }




  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #0A2451;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  