.gallery-container {
    width: 85%;
    margin: auto;
  }
  
  // .rg-image {
  //   object-fit: cover;
  //   height: 200px;
  //   width: 200px;
  // }
  .photogalleryHeading{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
    color: #0A2451;
    text-decoration: underline;
    text-shadow: 2px 4px 4px rgba(46,91,173,0.6);
    letter-spacing: 5px;
  }
  @media only screen and (max-width: 768px) {
    .rg-image {
      height: 100px;
      width: 100px;
    }
  }
  