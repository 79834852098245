.imageGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: auto;
}

// .administration_images1{
//   width: 50%;
//   height: 50%;
// }

.imageGrid1 {
  display: flex;

  // flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.imageGridContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imageWrapper {
  margin: 10px;
  flex-basis: calc(25.33% - 20px);

  // flex-grow: 1;
}

.imageWrapper1 {
  margin: 10px;
  flex-basis: calc(33.33% - 20px);
  // flex-grow: 1;
}

.aspectRatioWrapper {
  position: relative;
  padding-bottom: 100%;

  width: 90%;
  // height: 80%;
  
}

.imageMatter{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  // margin: 0%;
}

.aspectRatioWrapper1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 25%;
}

.aspectRatioWrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// .administration_images1 {
//   width: 440px;
//   height: 440px;
// }

.administration_images1 .administration_images1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 3% !important;
  height:3% !important;
  margin: auto;
  object-fit: cover;
}

.admissionPage_headings {
  text-align: center;
  padding-top: 50px;
  color: #0A2451;
  text-decoration: underline;
  text-shadow: 2px 4px 4px rgba(46, 91, 173, 0.6);
  letter-spacing: 5px;
}

@media (max-width: 767px) {
  .imageWrapper {
    flex-basis: 100%;
  }
}


.details {
  // background-color: #f2f2f2; /* Background color for the details section */ /* Padding around the details */
  margin: 0px; /* Margin around each staff member section */
  // border: 1px solid #ddd; /* Border around each staff member section */
  border-radius: 5px; /* Rounded corners for the border */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle elevation effect */
  // align-items:start;
  // text-align: center;
  // justify-content: left;
}



.staffName {
  margin: 5px 0; /* Margin for the staff member's name */
  font-size: 18px; /* Font size for the name */
  font-weight: bold; /* Bold font for the name */
}

.staffDetails {
  margin: 5px 0; /* Margin for other details (designation, department, contact) */
  font-size: 16px; /* Font size for other details */
  color: #555; /* Text color for other details */
}