/* CSS for Image Grid */
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns in the grid */
  gap: 15px; /* Adjust the gap between images */
  max-width: 75%; /* Adjust the maximum width of the grid */
  margin: 0 auto; /* Center align the grid */
}

/* CSS for Individual Images */
.image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain the aspect ratio and crop as needed to cover the grid item */
  border: 1px solid #ddd; /* Add a border to each image */
  border-radius: 8px; /* Round the corners of the images */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow to the images */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add a transition for zoom and shadow effect */
}

/* Add zoom effect on hover */
.image-grid img:hover {
  transform: scale(1.05); /* Zoom in the image by 5% on hover */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Increase the shadow on hover */
}

/* CSS for Loading and No Data Messages */
.image-messages {
  text-align: center; /* Center align the messages */
  margin: 20px 0; /* Add some margin for spacing */
}

/* CSS for Loading Message */
.image-messages .loading {
  font-size: 18px; /* Adjust the font size */
  color: #555; /* Choose a color for the message */
}

/* CSS for No Data Found Message */
.image-messages .no-data {
  font-size: 24px; /* Adjust the font size */
  color: #f00; /* Choose a color for the message */
}

.lightBoxDiv {
  position: absolute;
  z-index: 5000; /* Correct the value to remove the double quotes */
}
