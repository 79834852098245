/* CarouselDelete.scss */

/* Add margin around the image gallery */
.image-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 20px;
  
    /* Add margin around the grid to create spacing from the screen edges */
    @media (min-width: 768px) {
      margin: 20px auto; /* Center the grid horizontally on larger screens */
      max-width: 1200px; /* Limit the grid width to prevent excessive spreading */
    }
  
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr); /* Switch to a 2-column grid on smaller screens */
    }
  
    @media (max-width: 480px) {
      grid-template-columns: 1fr; /* Switch to a single column on the smallest screens */
    }
  }
  
  .image-card {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease; /* Add a smooth transition on hover */
  }
  
  .image-card:hover {
    transform: scale(1.05); /* Add a slight zoom effect on hover */
  }
  
  .image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .details {
    padding: 10px;
  }
  
  h2 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
  }
  
  p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 8px;
  }
  
  .delete-button {
    background-color: #f44336;
    color: #ffffff;
    border: none;
    border-radius: 0;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #d32f2f;
    }
  }
  
  /* Animation for the deleting image */
  .image-card.deleting {
    opacity: 0.5;
    transition: opacity 0.5s ease;
  }




.error-alert {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f44336;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
}

/* Add these styles to your stylesheet */
.Errormodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Errprmodal-content {
  background-color: #f44336;
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  
  
}

.Errormodal-close {
  cursor: pointer;

}

  