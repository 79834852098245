.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 36px;
  font-weight: 600;
}

.header h5 {
  font-size: 16px;
  font-weight: 400;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container img {
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

.footer {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .image-container img {
    width: 100%;
    max-width: none;
  }
}


.pdf-viewer {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .pdf-viewer {
    height: 100%;
  }
}
