.profile-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  
  .profile-image {
    width: 500px; /* Adjust as needed */
    height: 350px; /* Adjust as needed */
    // border-radius: 50%; /* Makes the image round */
    border: 5px solid #fff; /* White border around the image */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  }
  
  .profile-details {
    text-align: center;
    margin-top: 20px;
  }
  
  h1 {
    font-size: 24px;
    color: #333; /* Adjust the text color */
  }
  
  h2 {
    font-size: 18px;
    color: #666; /* Adjust the text color */
  }
  
  // p {
  //   font-size: 16px;
  //   color: #999;
  //   max-width: 400px;
  //   margin: 0 auto;
  // }
  